<template>
  <div class="card-skeleton">
    <div class="skeleton-header">
      <div class="avatar-circle"></div>
      <div class="author-line"></div>
    </div>
    <div class="skeleton-body">
      <div class="body-title">
        <div class="text-line-top"></div>
        <div class="text-line-bottom"></div>
      </div>
      <div class="body-image"></div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@keyframes skeleton-loading {
  0% {
    background-color: rgba(255, 255, 255, 0.02);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.04);
  }
}
.card-skeleton {
  padding: 40px 49px;
  background-color: #121212;
  margin-bottom: 4px;
  @media screen and (max-width: $mobile) {
    padding: 20px 0;
  }
  .skeleton-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    @media screen and (max-width: $mobile) {
      margin-bottom: 20px;
      padding: 0 16px;
    }
    .avatar-circle {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      background-color: #202020;
      margin-right: 16px;
      // animation: skeleton-loading 0.5s linear infinite alternate;
      position: relative;
      overflow: hidden;
      @media screen and (max-width: $mobile) {
        height: 34px;
        width: 34px;
        margin-right: 12px;
      }
    }
    .avatar-circle::after {
      @include animatedLine;
    }
    .author-line {
      height: 34px;
      width: 94px;
      background-color: #202020;
      // animation: skeleton-loading 0.5s linear infinite alternate;
      position: relative;
      overflow: hidden;
    }
    .author-line::after {
      @include animatedLine;
    }
  }
  .skeleton-body {
    max-width: 460px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .body-title {
      @media screen and (max-width: $mobile) {
        padding: 0 16px;
        order: 2;
      }
      .text-line-top,
      .text-line-bottom {
        height: 20px;
        background-color: #202020;
        position: relative;
        overflow: hidden;
      }
      .text-line-top {
        max-width: 345px;
        margin-bottom: 8px;
        @media screen and (max-width: $mobile) {
          max-width: 225px;
        }
      }
      .text-line-bottom {
        max-width: 165px;
        @media screen and (max-width: $mobile) {
          max-width: 113px;
        }
      }
      .text-line-top::after,
      .text-line-bottom::after {
        @include animatedLine;
      }
    }
    .body-image {
      height: 268px;
      background-color: #202020;
      margin-top: 20px;
      position: relative;
      overflow: hidden;
      // animation: skeleton-loading 0.5s linear infinite alternate;
      @media screen and (max-width: $mobile) {
        order: 1;
        height: 230px;
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
    .body-image::after {
      @include animatedLine;
    }
  }
}
</style>